<template>
   
   <div v-if="nouser">
       <br /><br />
       <center>
           <span v-if="language=='fr'">Vous devez être connecté pour accéder à cette page.</span>
           <span v-else>You must be logged in to access to this page.</span>
       </center>
       </div>
  <div class="page-template-home-closed-new" v-else>
      <br /><br />
       <content-loader  v-if="loaded==false"
            viewBox="0 0 410 40"
            primaryColor="#f3f3f3"
            secondaryColor="#cccccc"
            >
            <rect x="55" y="0" rx="10" ry="10" width="300" height="20" />

         
            
        </content-loader>
        
      <TopLikes :language="language" v-else :loaded="loaded" />
       
      <div class="collection-filter" >
          <div class="item" v-if="language=='fr'" :class="{active:selectedFilter=='likes'}" @click="filterLike('likes')" >Produits favoris</div>
          <div class="item" v-else :class="{active:selectedFilter=='likes'}" @click="filterLike('likes')" >Favorites products</div>
          <div class="item" v-if="language=='fr'"  :class="{active:selectedFilter=='favorites'}"   @click="filterLike('favorites')" >Produits enregistrés</div>
          <div class="item" v-else :class="{active:selectedFilter=='favorites'}"   @click="filterLike('favorites')" >Saved products</div>
          
          <div class="bar" :style="'left:'+barPosition+'%;width:'+barWidth+'%;'"></div>
      </div>
      <br /><br />
       <div class="contenumob select-column">
            <img src="../../public/assets/images/col1.svg" width="12" @click="columns=1" :class="{active:columns==1}" />    <img @click="columns=2" src="../../public/assets/images/col2.svg" :class="{active:columns==2}" width="12" /> 
            <br /><br />
        </div>
    <div class="collection home-section-new">
        <div  v-if="selectedFilter=='likes'">
            <content-loader v-if="loaded==false"
                viewBox="0 0 520 200"
                primaryColor="#f3f3f3"
                secondaryColor="#cccccc"
            >
                <rect x="20" y="0" rx="0" ry="0" width="150" height="150" />
                <rect x="20" y="160" rx="3" ry="3" width="150" height="6" />
                <rect x="180" y="0" rx="0" ry="0" width="150" height="150" />
                <rect x="180" y="160" rx="3" ry="3" width="150" height="6" />
                <rect x="350" y="0" rx="0" ry="0" width="150" height="150" />
                <rect x="350" y="160" rx="3" ry="3" width="150" height="6" />
            </content-loader>
 
            <ProductLikes 
            :language="language"
                :columns="columns"
                :like_glasses="like_glasses"
                :loaded="loaded"
                :like_photos="like_photos"
                @likePhotoEv="likePhoto"
            
            />

        </div>
        
       <div v-if="selectedFilter=='favorites'">

            <ProductFavorites
            :language="language"
                :columns="columns"
                @addToCartEv="addToCart"
                @setFavoritesEv="setFavorites"
            />

       </div>
      
    
    </div>
    
    <br /><br /><br /><br />
    <Footer :language="language" :displayModalMenu="displayModalMenu" />
  </div>
</template>

<script>
import Footer from '../components/layout/Footer.vue';
import jQuery  from 'jquery';
import ScrollMagic  from 'ScrollMagic';
import emitter from 'tiny-emitter/instance';
import Flickity from 'vue-flickity';
import {APIRequest} from "@simb2s/senseye-sdk";
import { ContentLoader } from 'vue-content-loader';

// Import children
import TopLikes from "../components/children/Likes/TopLikesComponent.vue"
import ProductLikes from "../components/children/Likes/ProductLikeComponent.vue"
import ProductFavorites from '../components/children/Likes/ProductFavoritesComponent.vue';

export default {
    name: 'Collection',
    components: {
        Footer,
        Flickity,
        ContentLoader,
        TopLikes,
        ProductLikes,
        ProductFavorites,
    },
    data() {
        return {
        barWidth:50,
        nouser:false,
        barPosition:0,
        like_glasses:[],
        // favorite_glasses:[],
        controller: null,
        loaded:false,
        tween: null,
        columns:1,
        scene: null,
        email:null,
        currentOption:'colors',
        showModal:false,
        currentImage:'',
        currentColor:'color0',
        showDevices:false,
        selectedFilter:'likes',
        flickityOptions: {
        cellAlign: 'center',
        prevNextButtons: false,
        contain: true,
        favorites:[],
        likes:[],
        like_photos:[],
        // favorite_photos:[]
      }
        }
    },
    props:["displayModalMenu","language"],
    methods:{
        async likePhoto(liked,like_id,glass_index)
        {
            if(liked==false)
            {
                if(localStorage.id_user_senseye_auth)
                {
                    if(localStorage.id_user_senseye_auth!=null && localStorage.id_user_senseye_auth!='')
                    {
                        let data={}
                        data.user={}
                        data.glass={}
                        data.glass.id=this.like_glasses[glass_index].id
                        data.user.id=localStorage.id_user_senseye_auth
                        await APIRequest.create('likes',data)
                    }
                   
                }
                this.like_glasses[glass_index].liked=true;
            }
            else
            {
                if(like_id!=null)
                {
                    await APIRequest.delete('likes',like_id)
                }
                this.like_glasses[glass_index].liked=false;
            }
            await this.getLikes()
        },
        filterLike(filter)
        {
            this.selectedFilter=filter
            if(filter=='likes')
            {
                this.barWidth=50
                this.barPosition=0
            }
            else
            {
                this.barWidth=50
                this.barPosition=50
            }
        },
        closeModal($event)
        {
             if (jQuery($event.target).parents('.content').length) {

        }
            else
            {
                this.showModal=false
            }
        },

        // async removeFavorite(id) {
        //     this.loaded=false
        //     await APIRequest.delete('favorites',id)
        //     await this.getFavorites()
        //     this.loaded=true
        // },
        // async getFavorites()  {

        //     let favorites=await APIRequest.list('favorites',localStorage.id_user_senseye_auth)
        //     this.favorite_glasses=[]
        //     this.favorites=favorites[0]
        //     this.favorite_id=[]
        //     this.favorite_photos=[]
        //     this.displayDetails=[]

        //     for(let i=0;i<this.favorites.length;i++)
        //     {
             
        //         this.favorite_glasses.push(this.favorites[i].glass)
        //         this.displayDetails.push(false)
        //         this.favorite_id.push(this.favorites[i].id)

        //         if(this.favorites[i].tint!==null)
        //         {
        //             var request = "?glass="+this.favorites[i].glass.id+"&color="+this.favorites[i].color.id+'&texture='+this.favorites[i].texture.id+'&branch='+this.favorites[i].branch.id+'&tint='+this.favorites[i].tint.id;
        //         }
        //         else
        //         {
        //             var request = "?glass="+this.favorites[i].glass.id+"&color="+this.favorites[i].color.id+'&texture='+this.favorites[i].texture.id+'&branch='+this.favorites[i].branch.id+'&tint=5';
        //         }
        //         let photos= await APIRequest.list_with_request('photos',request)
        //         let photo=photos[0]
        //         this.favorite_photos.push(photo[0].photo)
        //         console.log(this.favorite_glasses)
        //     }

        // },
         setFavorites(favorites){
            this.favorites = favorites;
         },
         async getLikes()  {

            let likes=await APIRequest.list('likes',localStorage.id_user_senseye_auth)
            this.like_glasses=[]
            this.likes=likes[0]

            for(let i=0;i<this.likes.length;i++)
            {
                this.like_glasses.push(this.likes[i].glass)
            }
            this.like_photos=[]
            for(let i=0;i<this.like_glasses.length;i++)
            {
                let photo=this.like_glasses[i].photos
                let photos=this.like_glasses[i].photos;
                photo=photos[0];
                this.like_photos.push(photo)
            }
        },
        changeNewsletterEmail(email)
        {
            this.email=email
        },
        chooseOption(option)
        {
            this.currentOption=option
            this.currentColor='color1'
        },
        displayModal($event)
        {
            this.currentImage=jQuery($event.target).attr('src');
            this.showModal=true;
        },
        chooseColor(color)
        {
            this.currentColor=color
        },
        displayDevices()
        {
            if(this.showDevices==true)
            {
                this.showDevices=false
            }
            else
            {
                this.showDevices=true
            }
        },
        async addToCart(index){
            if(this.favorites[index].glass_type!=null && this.favorites[index].glass_option!=null)
            {

                
                let options={}
                if(this.favorites[index].branch!=null)
                {
                    options.branch={}
                    options.branch.id=this.favorites[index].branch.id
                    options.branch_price=this.favorites[index].branch.price
                }
                else
                {
                    options.branch=null
                
                    options.branch_price=0
                }
                
                if(this.favorites[index].color!=null)
                {
                    options.color={}
                    options.color_price=this.favorites[index].color.price
                    options.color.id=this.favorites[index].color.id
                }
                else
                {
                    options.color=null
                    options.color_price=0
                }
            if(this.favorites[index].texture!=null)
                {
                    options.texture=this.favorites[index].texture
                    options.texture_price=this.favorites[index].texture.price
                }
                else
                {
                    options.texture=null
                    options.texture_price=0
                }
                if(this.favorites[index].tint!=null)
                {
                    options.tint={}
                    options.tint.id=this.favorites[index].tint.id
                    options.tint_price=this.favorites[index].tint.price

                }
                else
                {
                    options.tint=null
                
                    options.tint_price=0
                }
                
                if(this.favorites[index].glass_type!=null)
                {
                    options.glass_type={}
                    options.glass_type.id=this.favorites[index].glass_type.id
                    options.glass_type_price=this.favorites[index].glass_type.price
                }
                else
                {
                    options.glass_type=null
                    options.glass_type_price=0
                }
                if(this.favorites[index].glass_option!=null)
                {
                    options.glass_option={}
                    options.glass_option.id=this.favorites[index].glass_option.id
                    options.glass_option_price=this.favorites[index].glass_option.price
                }
                else
                {
                    options.glass_option=null
                
                    options.glass_option_price=0
                }
                
                options.engraving_text=this.favorites[index].engraving_text
            
                if(this.favorites[index].scan!=null)
                {
                    options.scan={}
                    options.scan.id=this.favorites[index].scan.id
                    options.size=null
                }
                else
                {
                    options.scan=null
                    options.size=this.favorites[index].size
                }
                

                if(this.favorites[index].glass_type!='nocorrection' && this.favorites[index].glass_type!=null)
                {
                    emitter.emit('add-to-cart-collection', this.favorites[index].glass.id, options,'prescription');
                   
                }
                else
                {
                    emitter.emit('add-to-cart-collection', this.favorites[index].glass.id,options,'noprescription');
                }

            }
            else
            {
                this.$router.push({ name: 'collection-glass', params: { id: this.favorites[index].glass.id, favorite:this.favorites[index].id } })
            }
        }
    },
    async mounted()
    {
        if(!localStorage.id_user_senseye_auth)
        {
            this.nouser=true
        }
        else
        {
            await this.getLikes();
            // await this.getFavorites();
        }
        if (this.$route.params.toggle) {
            this.filterLike(this.$route.params.toggle)
        }
        this.loaded=true
    }
}
</script>
<style scoped>
div.products div.product .bottom {
    padding:10px;
}
.products .product .like img {
    width:26px;
}
.products .product
{
    margin-bottom:160px;
}
.product.deployed
{
    margin-bottom:520px !important;
}
.size_choice
{
    width:100%;
}
.details {
    position: absolute;
    bottom:-130px;
    width:100%;
    transform: translateY(100%);
}
.details .product-detail label
{
    text-align:left;
    font-size:13px;
    display: inline-block;
}
.details .product-detail.scans
{
    border-bottom: 0;
}
.details .product-detail
{
    text-align:left;
    padding-top:3px;
    border-bottom:1px solid black;
    font-size:13px;
}
.product-buttons button.active
{
    background: #fff;
    color:#000;
}
.product-detail .subtotal
{
    float:right;
    display:inline-block;
    width:auto;

}
.product-buttons
{
    width:100%;
    text-align:left;
}
.product-buttons button:nth-child(2)
{
    float:right;
}
.product-buttons button
{
    width:45%;
    display: inline-block;
    text-align: center;
    background: #000;
    border:1px solid black;
    color:#fff;
    transition:all 0.4s;
    border-radius: 48px;
    font-weight: 300;
    text-decoration: none;
    text-transform: none;
    font-size: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.product-buttons
{
    position:absolute;
    bottom:-120px;
}
.loader
{	

	background:rgba(255,255,255,0.8);
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;

	z-index:99;
}
 .loader img
{
	width:200px;
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
}
.colors-count .pls
{
background: linear-gradient(80deg, #95B4F2 0%, #9F96F0 100%) 0% 0% no-repeat padding-box;
-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.personnalisation-button::before
{
    content:' ';
    position:absolute;
    left:5px;
    top: 7px;
    width:13px;
    height:13px;
    background-size:100% 100%;
    display:block;
    background-image:url(../../public/assets/images/bullet.svg);
}
.personnalisation-button
{
    position:relative;
    font-size:13px;
    background: transparent linear-gradient(90deg, #CBDCF8 0%, #DDD5F7 100%) 0% 0% no-repeat padding-box;
border: 1px solid #FFFFFF00;
border-radius: 8px;
padding:5px 10px 5px 25px;
}
.like
{
    cursor:pointer;
    position:absolute;
    top:10px;
    z-index:3;
    text-align: right;
    right:15px;
}
.personnalisation-actions
{
    position:absolute;
    top:10px;
    text-align: left;
    left:15px;
}
.colors-count
{
    font-size:13px;

}
.carousel-cell a
{
    text-decoration: none;
    color:#000;
    position:absolute;
    z-index:1;
    left:0;
    top:0;
    width:100%;
    height:100%;
}
.title-collection .outline
{
    background-image: -webkit-linear-gradient(#000 50%, #000 50%);
    background-position: 0 0;
    background-size: 0% 0%;
    text-stroke: 1px #000;
    -webkit-text-stroke: 1px #000;
    color: #fff;
    white-space: nowrap;
    color: transparent;
}
.title-collection
{
    text-align: center;
    font-size:70px;
}
.collection-description
{
    text-align:center;
    max-width:700px;
    width:70%;
    margin-left:auto;
    margin-right:auto;
}
.collection-filter .item:nth-child(2)
{
width:50%;
}
.collection-filter .item:first-child
{
width:50%;
}
.collection-filter .item:nth-child(3)
{
width:48%;
}
.collection-filter .item
{
    
    cursor:pointer;
    display:inline-block;
}
.collection-filter .bar
{
    transition:all 0.5s ease-in-out;
    width:33%;
    height:2px ;
    background:#000;
    position:absolute;
    bottom:0;
}
.collection-filter
{
    position:relative;
    font-size:18px;
    text-align: center;
    width:380px;
    margin-left:auto;
    margin-right:auto;
    border-bottom:0.5px solid #808080;
    padding-bottom:10px;
}
.collection-text
{
    font-size:13px;
}

.size_choice
{
    margin-top:20px !important;
    display:block !important;
}
/* @media screen and (max-width:760px) {
    .collection-filter
    {
        width:320px;
        font-size:15px;
    }
    .collection-description
    {
        width:90%;
    }
    .title-collection
    {
        font-size:32px;
    }

    body .products
    {
        padding-left:5%;
        padding-right: 5%;
    }
    body .products .product .bottom .product-title
    {
        font-size:13px;
    }

    body .products.cols2 .colors-count
    {
        font-size:8px;
    }
    body .products.cols2 .personnalisation-button
    {
        font-size:8px;
    }
    body .products.cols2 .product
    {
        width:48%;
    }
    
    body .products.cols2 .product .bottom .product-price
    {
        font-size:8px;
    }
    .select-column img.active
    {
        opacity:1;
    }
    
    .select-column img
    {
        opacity:0.5;
        display:inline-block;
        margin-left:5px;
        margin-right: 5px;
    }
    .select-column
    {
        width:100%;
        text-align: center;
    }
} */

</style>