<template>
    <content-loader v-if="favoritesLoaded==false" viewBox="0 0 300 100">

    </content-loader>
    <template v-else>
        <div class="products" :class="{ cols2: columns == 2 }" v-if="favorites.length>0">

            <div class="carousel-cell product product-favorite" v-for="(favorite, index) in favorites" :key="favorite.id"
                :class="{ deployed: displayDetails[index] }">

                <a @click="$router.push({ name: 'collection-glass', params:{id:favorite.glass.id, favorite:favorite.id} })" ></a>
                
                <CartImage :cart_item="favorite" />
                <div class="personnalisation-actions">
             
                    <div  v-if="language=='fr'" class="personnalisation-button">Personnaliser</div>
                    <div  v-else  class="personnalisation-button">Customize</div>
                </div>
                <div class="like" @click="removeFavorite(favorite.id)">
                    <img src="../../../../public/assets/images/cross.svg" width="40" />
                </div>

                <div class="bottom">
                    <span class="product-title">{{ favorite.glass.name }}</span>
                    <!-- <span class="product-price">{{ favorite.amount }}€</span> -->

                </div>
                <div class="product-buttons">
                    <button   :class="{ active: displayDetails[index] }" @click="toggleDisplayDetails(index)">Infos</button>
                    <button  v-if="language=='fr'"  @click="addToCart(index)">Acheter</button>
                    <button  v-else  @click="addToCart(index)">Buy</button>
                </div>
                <div class="details details-favorites" v-if="displayDetails[index]">
                    <a @click="$router.push({ name: 'collection-glass', params:{id:favorite.glass.id, favorite:favorite.id} })" >
                        
                         
                        <div class='product-detail'>
                            
                            
                            <div>
                                <span v-if="language=='fr'">Verres :</span>
                                <span v-else>Lenses :</span>
                                
                                    <span v-if="favorite.glass_option!==null && favorite.glass_type!==null">{{favorite.glass_option.name}} - {{favorite.glass_type.name}}</span>
                                    <span v-else-if="favorite.glass_option!==null">{{favorite.glass_option.name}} - <span v-if="language=='fr'">Sans correction</span><span v-else>No correction</span></span>
                                    <span v-else-if="favorite.glass_type!==null">{{favorite.glass_type.name}}</span>
                                    <span v-else><span v-if="language=='fr'">Sans correction</span><span v-else>No correction</span></span>
                                
                                
                                <span v-if="favorite.glass_type!==null"><br /><span v-if="language=='fr'">Type de verre :</span><span v-else>Lens type:</span>
                                    {{favorite.glass_type.name}} </span>


                                <span v-if="favorite.tint!==null"><br /><span v-if="language=='fr'">Teinte de verre :</span><span v-else>Lense tint:</span> {{favorite.tint.name}}</span>

                                <span class='subtotal' v-if="(favorite.tint!=null || favorite.glass_option!=null  || favorite.glass_type!=null)">

                                    <span
                                        v-if="favorite.tint!==null && favorite.glass_option!==null && favorite.glass_type!==null">{{favorite.tint.price+favorite.glass_option.price+favorite.glass_type.price}}</span>

                                    <span
                                        v-else-if="favorite.tint!==null && favorite.glass_option!==null">{{favorite.tint.price+favorite.glass_option.price}}</span>

                                    <span
                                        v-else-if="favorite.glass_option!==null">{{favorite.glass_option.price}}</span>

                                    <span v-else-if="favorite.tint!==null">{{favorite.tint.price}}</span>

                                    <span v-if="favorite.tint!==null || favorite.glass_option!==null || favorite.glass_type!==null">€</span>
                                </span>
                            </div>
                        
                       
                            <div v-for="order_item_customization in favorite.order_item_customizations" :key="order_item_customization.id" >
                                <span v-if="(order_item_customization.model_part.type=='color' || order_item_customization.model_part.type=='texture_color') && order_item_customization.model_part.render_type!='manchon' && order_item_customization.model_part.render_type!='hinge'">
                                    <span class="detail-custom" v-if="order_item_customization.model_part.type=='color'">
                                        <span v-if="language=='fr'">Couleur</span><span v-else>Color</span> {{order_item_customization.model_part.name}} :
                                        {{order_item_customization.color.name}}
                                        <span v-if="order_item_customization.color.price" class='subtotal'>{{order_item_customization.color.price}}€</span>
                                    </span>
                                    <span class="detail-custom" v-else>
                                        <span v-if="language=='fr'">Couleur</span><span v-else>Color</span> {{order_item_customization.model_part.name}} :
                                        {{order_item_customization.color.name}}
                                        <span v-if="order_item_customization.color.price" class='subtotal'>{{order_item_customization.color.price}}€</span>
                                    
                                    </span>
                                    <span class="detail-custom" v-if="order_item_customization.texture">
                                        Texture {{order_item_customization.model_part.name}} :
                                        {{order_item_customization.texture.name}}
                                        <span v-if="order_item_customization.texture.price" class='subtotal'>{{order_item_customization.texture.price}}€</span>
                                    </span>
                                    <span class="detail-custom" v-if="order_item_customization.texture_style">
                                        <span v-if="language=='fr'">Style de texture</span><span v-else>Texture style</span> {{order_item_customization.model_part.name}} :
                                        {{order_item_customization.texture_style.name}}
                                        <span v-if="order_item_customization.texture_style.price" class='subtotal'>{{order_item_customization.texture_style.price}}€</span>
                                    </span>
                                </span>
                            </div>

                        </div>

                        <div class='product-detail'
                            v-if="favorite.engraving_text != null && favorite.engraving_text != ''">
                            <label v-if="language=='fr'">Gravure :</label>
                            <label v-else>Engraving :</label>
                            {{ favorite.engraving_text }}
                            <span class='subtotal'>10€</span>
                        </div>

                        
                    </a>
                </div>
            </div>
        </div>
        <div v-else>
            <center>
                <span v-if="language=='fr'">Vous n'avez aucun produit favoris pour le moment.</span>
                <span v-else>You don't have any favorite product for the moment.</span>
            </center>
        </div>
    </template>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

import {APIRequest} from "@simb2s/senseye-sdk";
import CartImage from "../Cart/CartImageComponent.vue";

export default {
    name: "ProductFavorites",
     components: {
       
        ContentLoader,
        CartImage
    },
    props: ["columns","language"],
    methods: {
        calculatePhoto(index) {
            let urlPhoto='https://api.sens-eye.fr/api/photo-single'
       
            let cart_item=this.favorites[index]

             if(cart_item.tint!=null) {
                if(cart_item.texture_style!=null) {
                    urlPhoto+='?glass='+cart_item.glass.id+'&color='+cart_item.color.id+'&texture='+cart_item.texture.id+'&branch='+cart_item.branch.id+'&tint='+cart_item.tint.id+'&texture_style='+cart_item.texture_style.id
                }
                else {
                    urlPhoto+='?glass='+cart_item.glass.id+'&color='+cart_item.color.id+'&texture='+cart_item.texture.id+'&branch='+cart_item.branch.id+'&tint='+cart_item.tint.id
                }
            }
            else {
                if(cart_item.texture_style!=null) {
                    urlPhoto+='?glass='+cart_item.glass.id+'&color='+cart_item.color.id+'&texture='+cart_item.texture.id+'&branch='+cart_item.branch.id+'&tint=5&texture_style='+cart_item.texture_style.id
                }
                else {
                    urlPhoto+='?glass='+cart_item.glass.id+'&color='+cart_item.color.id+'&texture='+cart_item.texture.id+'&branch='+cart_item.branch.id+'&tint=5'
                }
            }

             urlPhoto+='&number=0'
            return urlPhoto
        },
        addToCart(i) {
            this.$emit('addToCartEv', i)
        },
        toggleDisplayDetails(index){
            this.displayDetails[index] = !this.displayDetails[index]
        },
        async getFavorites() {

            let favorites = await APIRequest.list('favorites', localStorage.id_user_senseye_auth)
            this.favorite_glasses = []
            let favs=favorites[0]
            
            
         
        
            

            for (let i = 0; i < favs.length; i++) {
                
                favs[i].glass=favs[i].order_item_customizations[0].model_part.glass

                for (let j = 0; j < favs[i].order_item_customizations.length; j++) {
                if(favs[i].order_item_customizations[j].tint)
                {
                        favs[i].tint=favs[i].order_item_customizations[j].tint
                }
               //favs[i].glass=favs[i].order_item_customizations[0].model_part.glass
            }
         
               
            }
            this.favorites = favs
            this.$emit('setFavoritesEv', this.favorites)
            // this.favorite_id = []
            // this.favorite_photos = []
            // this.displayDetails = []

            // for (let i = 0; i < this.favorites.length; i++) {

            //     this.favorite_glasses.push(this.favorites[i].glass)
            //     this.displayDetails.push(false)
            //     this.favorite_id.push(this.favorites[i].id)

            //     if (this.favorites[i].tint !== null) {
            //         var request = "?glass=" + this.favorites[i].glass.id + "&color=" + this.favorites[i].color.id + '&texture=' + this.favorites[i].texture.id + '&branch=' + this.favorites[i].branch.id + '&tint=' + this.favorites[i].tint.id;
            //     }
            //     else {
            //         var request = "?glass=" + this.favorites[i].glass.id + "&color=" + this.favorites[i].color.id + '&texture=' + this.favorites[i].texture.id + '&branch=' + this.favorites[i].branch.id + '&tint=5';
            //     }
            //     let photos = await APIRequest.list_with_request('photos', request)
            //     let photo = photos[0]
            //     this.favorite_photos.push(photo[0].photo)
            //     console.log(this.favorite_glasses)
            //     this.$emit('setFavoritesEv', this.favorites)
            // }

        },
        async removeFavorite(id) {
            this.loaded=false
            this.favoritesLoaded=false
            await APIRequest.delete('favorites',id)
            await this.getFavorites()
            this.loaded=true
            this.favoritesLoaded=true
        },
    },
    async mounted() {
        await this.getFavorites()
        this.favoritesLoaded=true
    },
    data() {
        return {
            favorite_glasses: [],
            favorite_photos: [],
            favorites: [],
            favorite_id: [],
            displayDetails:[],
            favoritesLoaded:false
        }
    }
}
</script>

<style scoped>
div.products div.product .bottom {
    padding:10px;
}
.products .product .like img {
    width:26px;
}
.products .product
{
    margin-bottom:160px;
}
.product.deployed
{
    margin-bottom:520px !important;
}
.size_choice
{
    width:100%;
}
.details {
    position: absolute;
    bottom:-130px;
    width:100%;
    transform: translateY(100%);
}
.details .product-detail label
{
    text-align:left;
    font-size:13px;
    display: inline-block;
}
.details .product-detail.scans
{
    border-bottom: 0;
}
.details .product-detail
{
    text-align:left;
    padding-top:3px;
    border-bottom:1px solid black;
    font-size:13px;
}
.product-buttons button.active
{
    background: #fff;
    color:#000;
}
.product-detail .subtotal
{
    float:right;
    display:inline-block;
    width:auto;

}
.product-buttons
{
    width:100%;
    text-align:left;
}
.product-buttons button:nth-child(2)
{
    float:right;
}
.product-buttons button
{
    width:45%;
    display: inline-block;
    text-align: center;
    background: #000;
    border:1px solid black;
    color:#fff;
    transition:all 0.4s;
    border-radius: 48px;
    font-weight: 300;
    text-decoration: none;
    text-transform: none;
    font-size: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.product-buttons
{
    position:absolute;
    bottom:-120px;
}
.loader
{	

	background:rgba(255,255,255,0.8);
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;

	z-index:99;
}
 .loader img
{
	width:200px;
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
}
.colors-count .pls
{
background: linear-gradient(80deg, #95B4F2 0%, #9F96F0 100%) 0% 0% no-repeat padding-box;
-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.personnalisation-button::before
{
    content:' ';
    position:absolute;
    left:5px;
    top: 7px;
    width:13px;
    height:13px;
    background-size:100% 100%;
    display:block;
    background-image:url(../../../../public/assets/images/bullet.svg);
}
.personnalisation-button
{
    position:relative;
    font-size:13px;
    background: transparent linear-gradient(90deg, #CBDCF8 0%, #DDD5F7 100%) 0% 0% no-repeat padding-box;
border: 1px solid #FFFFFF00;
border-radius: 8px;
padding:5px 10px 5px 25px;
}
.like
{
    cursor:pointer;
    position:absolute;
    top:10px;
    z-index:3;
    text-align: right;
    right:15px;
}
.personnalisation-actions
{
    position:absolute;
    top:10px;
    text-align: left;
    left:15px;
}
.colors-count
{
    font-size:13px;

}
.carousel-cell a
{
    text-decoration: none;
    color:#000;
    position:absolute;
    z-index:1;
    left:0;
    top:0;
    width:100%;
    height:100%;
}
.title-collection .outline
{
    background-image: -webkit-linear-gradient(#000 50%, #000 50%);
    background-position: 0 0;
    background-size: 0% 0%;
    text-stroke: 1px #000;
    -webkit-text-stroke: 1px #000;
    color: #fff;
    white-space: nowrap;
    color: transparent;
}
.title-collection
{
    text-align: center;
    font-size:70px;
}
.collection-description
{
    text-align:center;
    max-width:700px;
    width:70%;
    margin-left:auto;
    margin-right:auto;
}
.collection-filter .item:nth-child(2)
{
width:50%;
}
.collection-filter .item:first-child
{
width:50%;
}
.collection-filter .item:nth-child(3)
{
width:48%;
}
.collection-filter .item
{
    
    cursor:pointer;
    display:inline-block;
}
.collection-filter .bar
{
    transition:all 0.5s ease-in-out;
    width:33%;
    height:2px ;
    background:#000;
    position:absolute;
    bottom:0;
}
.collection-filter
{
    position:relative;
    font-size:18px;
    text-align: center;
    width:380px;
    margin-left:auto;
    margin-right:auto;
    border-bottom:0.5px solid #808080;
    padding-bottom:10px;
}
.collection-text
{
    font-size:13px;
}

.size_choice
{
    margin-top:20px !important;
    display:block !important;
}
div.products .product.product-favorite {
    margin-bottom:160px;
}
.size {
    position: relative;
}
.size span {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
}
.personnalisation .head
{
  position: relative;
}
.personnalisation
{
    font-size:12px;

}
.details-favorites .product-detail
{
    border-bottom: 0 !important;
}
.size_choice
{
    margin-left:auto;
    margin-right:auto;
  margin-bottom:40px;
  text-align: center;
  display:block;
  border-radius:8px;
  width:290px;
  position:relative;
  padding:20px;
  vertical-align: top;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

}

.products .product .bottom .product-title
{
	display: inline-block;
    font-size:18px;
	text-transform: uppercase;
}


.title-size
{
    padding-left:10px;
    padding-right:10px;
    margin-top:-15px;
    color:rgba(103, 103, 103, 1);
}



.personnalisation .toggles .toggle-bg
{
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    position:absolute;
    left:0;
    height:95%;
    top:50%;
    transform: translateY(-50%);
    z-index:1;
    width:50%;
    transition:all 0.3s ease-in-out;
}
.personnalisation .toggles .toggle
{
     display:inline-block;
    width:49%;
    padding:10px 20px;
    position:relative;
    z-index:2;
     vertical-align: top;
}
.personnalisation .toggles
{
    margin-top:20px;
    min-width: 240px;
    margin-bottom:0;
    background:rgba(118, 118, 128, 0.12);
    border-radius: 8px;
    text-align: center;
    position:relative;
    border: 1px solid rgba(0, 0, 0, 0.04);
    display:inline-block;
    width:40%;
    vertical-align: top;
}


.size_choice  .size.active
{
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.size_choice .size
{
  cursor:pointer;
    background: rgba(231, 232, 232, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    display:inline-block;
    border-radius:50%;
    vertical-align: top;
    /* padding-top:10px;
    padding-bottom:10px; */
    padding: 10px 15px;
    margin-left:10px;
    height:30px;
    margin-right:10px;
    color:rgba(103, 103, 103, 1);
    white-space: nowrap;
}
.options-scan
{
	box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
	padding-top:10px;
	margin-top:-5px;
	border-bottom-left-radius:30px;
	border-bottom-right-radius:30px;
	padding-bottom:10px;
}
.options-scan
{
  left:0 !important;
}
.info .link
{
    right:-5px;
    position:relative;
    top:0px !important;
    
}
</style>