<template>
    <div class="products" :class="{ cols2: columns == 2 }" v-if="like_glasses.length>0 && loaded">
        <CollectionProduct 
                :language="language"
                :glasses="like_glasses"
                :selectedFilter="'solar'"
                :community="false"
                :selectedColorFilter="'all'"
                :slider="true"
                @likePhotoEv="likePhoto"
            />
       


    </div>
    <div v-else>
        <center>
            <span v-if="language=='fr'">Vous n'avez aucun produit favoris pour le moment.</span>
            <span v-else>You don't have any favorite product for the moment.</span>
        </center>
    </div>
</template>

<script>
import CollectionProduct from '../Collection/CollectionProductComponent.vue';

export default {
    name: "ProductLike",
    components:{CollectionProduct},
    props: ["columns", "like_glasses", "loaded", "like_photos","language"],
    methods:{
        likePhoto(glass, id, index){
            this.$emit('likePhotoEv', glass, id, index)
        },
        
    },
}
</script>

<style scoped>
.products .product .bottom .product-title
{
	display: inline-block;
    font-size:18px;
	text-transform: uppercase;
}
</style>