<template>
    <br /><br /><br />
      <div v-if="language=='fr'" class="title-collection">Favoris & <span class="outline">Enregistrements</span></div>
      <div v-else class="title-collection">Favorites & <span class="outline">Savings</span></div>
      <div class="collection-description">
        <content-loader  v-if="loaded==false"
            viewBox="0 0 410 124"
            primaryColor="#f3f3f3"
            secondaryColor="#cccccc"
            >

            <rect x="0" y="0" rx="3" ry="3" width="410" height="6" />
            <rect x="0" y="16" rx="3" ry="3" width="410" height="6" />
            <rect x="0" y="32" rx="3" ry="3" width="410" height="6" />
       

            <rect x="0" y="52" rx="10" ry="10" width="410" height="20" />

            
        </content-loader>
        <div v-else class="collection-text">
            {{ txt }}
      </div>
      </div>
</template>

<script>
import likesData from "../../../data/likes/likes_data"

export default {
    name: 'TopLikesComponent',
    props:["loaded","language"],
    data(){
        const {txt} = likesData
        return{
            txt
        }
    }
}
</script>

<style scoped>
.title-collection .outline
{
    background-image: -webkit-linear-gradient(#000 50%, #000 50%);
    background-position: 0 0;
    background-size: 0% 0%;
    text-stroke: 1px #000;
    -webkit-text-stroke: 1px #000;
    color: #fff;
    white-space: nowrap;
    color: transparent;
}
.title-collection
{
    text-align: center;
    font-size:70px;
}

.collection-description
{
    text-align:center;
    max-width:700px;
    width:70%;
    margin-left:auto;
    margin-right:auto;
}

.collection-text
{
    font-size:13px;
    margin: 20px 0 25px 0;
}

</style>